h1 {
    font-size: 3.5rem;
}

.hours-th {
    color:#ffffff;
    background:#10923b;
    border-bottom:4px solid #055520;
    border-right: 1px solid #055520;
    font-size:23px;
    font-weight: 100;
    padding:24px;
    text-align:center;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    vertical-align:middle;
  }
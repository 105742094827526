* {
    font-family: 'Roboto', sans-serif;
    text-align: center;
    color: #ffffff;
}

hr {
    max-width: 50px;
    border-width: 2px;
    border-color: #ffffff;
}

.sm-top-padding {
    padding-top: 3vh;
}

.sm-bottom-padding {
    padding-bottom: 5vh;
}

.md-top-padding {
    padding-top: 5vh;
}

.perfect-nails {
    font-size: 6rem;
}

#header {
    height: 82.5vh;
    text-align: center;
}

